exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tags-tsx": () => import("./../../../src/pages/tags.tsx" /* webpackChunkName: "component---src-pages-tags-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-discover-rokid-ar-glasses-ai-devices-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=K:/Development/MetaPixelX/electornic-blog/src/content/blog/discover-rokid-ar-glasses-ai-devices/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-discover-rokid-ar-glasses-ai-devices-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-exploring-12-in-1-sharkey-multi-tool-pocket-powerhouse-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=K:/Development/MetaPixelX/electornic-blog/src/content/blog/exploring-12-in-1-sharkey-multi-tool-pocket-powerhouse/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-exploring-12-in-1-sharkey-multi-tool-pocket-powerhouse-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-smart-floor-lamp-outon-torchiere-wifi-app-control-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=K:/Development/MetaPixelX/electornic-blog/src/content/blog/smart-floor-lamp-outon-torchiere-wifi-app-control/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-blog-smart-floor-lamp-outon-torchiere-wifi-app-control-index-mdx" */),
  "component---src-templates-post-list-template-tsx-content-file-path-src-content-blog-exploring-12-in-1-sharkey-multi-tool-pocket-powerhouse-index-mdx": () => import("./../../../src/templates/post-list-template.tsx?__contentFilePath=K:/Development/MetaPixelX/electornic-blog/src/content/blog/exploring-12-in-1-sharkey-multi-tool-pocket-powerhouse/index.mdx" /* webpackChunkName: "component---src-templates-post-list-template-tsx-content-file-path-src-content-blog-exploring-12-in-1-sharkey-multi-tool-pocket-powerhouse-index-mdx" */),
  "component---src-templates-tag-list-tsx-content-file-path-tag-device": () => import("./../../../src/templates/tag-list.tsx?__contentFilePath=/tag/device" /* webpackChunkName: "component---src-templates-tag-list-tsx-content-file-path-tag-device" */),
  "component---src-templates-tag-list-tsx-content-file-path-tag-tool": () => import("./../../../src/templates/tag-list.tsx?__contentFilePath=/tag/tool" /* webpackChunkName: "component---src-templates-tag-list-tsx-content-file-path-tag-tool" */)
}

